import { useState } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import Image from 'next/future/image'

import { Carousel, Picture } from '@smu-chile/pkg-unimarc-components'

import { addToHomeData, clickedPromos } from '@smu-chile/pkg-unimarc-hooks'

import styles from './showcaseweb.module.css'

export interface IShowCaseWebProps {
  data: object[]
  cfIndex?: number
  isMobile: boolean
  salesChannel: string
}

export const ShowCaseWeb = ({
  data,
  cfIndex,
  isMobile,
  salesChannel
}: IShowCaseWebProps) => {
  const [autoPlay, setAutoPlay] = useState(false)
  const contentObject = isMobile ? 'imageMobile' : 'imageDesktop'
  const containerProps = {
    maxWidth: '2800px',
    minHeight: '450px'
  }

  const handleRedirect = (promosData) => {
    const { reference, promotionId, promotionName } = promosData

    if (reference && promotionId && promotionName) {
      sessionStorage.setItem(
        reference,
        JSON.stringify({
          promotionId: promotionId,
          promotionName: promotionName
        })
      )
    }
    clickedPromos(promosData)
  }

  const handleStart = () => {
    return setAutoPlay(false)
  }

  const handleEnd = () => {
    return setAutoPlay(true)
  }

  return (
    <>
      <Carousel
        arrows={!isMobile}
        autoPlay={autoPlay}
        containerClass={classNames(styles.borderNone)}
        containerProps={containerProps}
        deviceType={isMobile ? 'mobile' : 'desktop'}
        focusOnSelect
        infinite
        itemClass={classNames(styles.borderNone)}
        opacityArrows
        pauseOnHover
        // showDots
        ssr
      >
        {data?.length > 0 &&
          data.map((item, index) => {
            const itemSaleChannels = item['fields']['saleChannels']
            const promoData = {
              ...item,
              locationId: `${cfIndex} - ${index + 1}`
            }

            if (
              itemSaleChannels?.includes(salesChannel) ||
              itemSaleChannels === undefined
            ) {
              return (
                <Picture
                  alt={item['alt']}
                  anchor
                  catchImgRef={(ref) => {
                    addToHomeData({ ref, promoData })
                  }}
                  height='100%'
                  key={item['label']}
                  linkWrapper={Link}
                  nextImage={Image}
                  onClick={() => {
                    handleRedirect(promoData)
                  }}
                  onTouchEnd={handleEnd}
                  onTouchStart={handleStart}
                  priority={index === 0}
                  src={item[contentObject]?.fields?.file?.url}
                  style={{
                    cursor: 'pointer',
                    height: '100%',
                    width: '100%',
                    marginTop: isMobile ? '0px' : '35px'
                  }}
                  title={item[contentObject]?.fields?.title}
                  url={item['reference']}
                  width='100%'
                />
              )
            }
            return null
          })}
      </Carousel>
    </>
  )
}
